@import "@/styles/theme/main.scss";
.icon {
  width: 10px;
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $primary-color;
  position: relative;
  margin-left: 4px;
  z-index: 2 !important;

  &::after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    background-color: $primary-color;
    left: 50%;
    z-index: -1;
    top: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: 1s cubic-bezier(0, 0, 0.2, 1) 0s infinite normal none running
      ping;
  }

  @keyframes pulse {
    0%,
    100% {
      width: 8px;
      height: 8px;
      opacity: 0;
    }
    50% {
      width: 20px;
      height: 20px;
      opacity: 0.3;
    }
  }
  @keyframes ping {
    75%,
    100% {
      transform: translate(-50%, -50%) scale(2.4);
      opacity: 0;
    }
  }
}
