@import "@/styles/theme/main.scss";
.image,
.text,
.multiplier,
.value,
.coin {
  @include skeleton($secondary: true);
}

.image {
  width: 80px;
  min-width: 80px;
  height: 80px;
}

.text {
  width: 60px;
  height: 14px;
}

.multiplier {
  width: 40px;
  height: 16px;
}

.value {
  width: 120px;
  height: 16px;
}

.coin {
  width: 17px;
  height: 17px;
  border-radius: 50%;
}
