@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  height: 100%;
  min-height: 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  padding-inline: 24px;
  min-width: 100%;

  &__wrapper {
    width: 100%;
    height: 100%;
    min-height: 1px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__image {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    border-radius: $border-radius;
    transition: all 0.3s;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__title {
      font-size: 32px;
      line-height: 2rem;
      font-weight: 700;
      color: $text-primary;
    }

    &__description {
      width: 100%;
      font-size: 14px;
      color: $text-secondary;
    }

    &__button {
      position: relative;
      z-index: 999999999;
    }
  }
}
