@import "@/styles/theme/main.scss";
.container {
  display: flex;
  align-items: center;
  gap: 8px;

  &__image {
    width: 20px;
  }

  &__label {
    text-align: flex-end;
    font-size: 12px;
    font-weight: 500;
    color: $text-primary;
    white-space: nowrap;
  }
}
