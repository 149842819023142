@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  max-width: 464px;
  height: auto;
  max-height: 90vh;
  background-color: $background-paper;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;

  &__close {
    font-size: 25px;
    position: absolute !important;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 16px;
    top: 8px;
    z-index: 9999;
  }

  @include media-tablet {
    all: unset;
    max-width: 100dvw;
    height: 100%;
    min-height: auto;
  }

  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    & {
      max-height: 100dvh;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
}
