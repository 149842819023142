@import "@/styles/theme/main.scss";
.overlay {
  position: fixed;
  z-index: 99999999;
  left: 0px;
  top: 0px;
  width: 100dvw;
  height: 100%;
  max-height: 100dvh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: unset;
  will-change: transform;

  @include media-phone {
    height: 100%;
    max-height: 100%;
    overflow-y: visible;
  }

  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    & {
      max-height: 100dvh;
    }
  }

  &--enter {
    animation: enter 0.25s ease-in forwards;
  }

  &--exit {
    animation: enter 0.25s ease-out forwards;
  }

  @keyframes enter {
    0% {
      opacity: 0;
      transform: scale(1.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
      pointer-events: all;
    }
  }

  @keyframes exit {
    0% {
      opacity: 0;
      transform: scale(1.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
      pointer-events: none;
    }
  }
}
