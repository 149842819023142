@import "@/styles/theme/main.scss";
.text,
.progress {
  @include skeleton($secondary: true);
}

.text {
  width: 60px;
  height: 14px;
}

.progress {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 80%;
}
