@import "@/styles/theme/main.scss";
.icon,
.label {
  @include skeleton($secondary: true);
}

.icon {
  width: 20px;
  height: 20px;
}

.label {
  width: 80px;
  height: 14px;
}
