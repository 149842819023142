@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  gap: 8px;
  height: 8px;
  padding-inline: 24px;

  &__item {
    width: 12px;
    height: 12px;
    border-radius: $border-radius;
    position: relative;
    background-color: $text-disabled;
    transition: all 0.3s;

    &--current {
      width: 32px;
      position: relative;
    }

    &--seen {
      background-color: $primary-color;
    }

    &__progress {
      height: 100%;
      position: absolute;
      inset: 0;
      width: 0%;
      border-radius: $border-radius;
      background-color: $primary-color;
    }
  }
}
