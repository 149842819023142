@import "@/styles/theme/main.scss";
.container {
  width: 100dvw;
  min-width: 100dvw;
  height: 100dvh;
  background-color: $background-default;
  position: relative;
  padding-block: 40px;

  &__content {
    height: 100%;
    display: flex;
    flex-direction: row !important;
    transition: all 0.3s;
  }
}
