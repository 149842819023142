@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &--row {
    display: flex;
    padding: 0px 24px;
    width: 100% !important;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--small {
      justify-content: unset;
      gap: 8px;
    }

    &--between {
      width: 100%;
    }

    &__labelprogress {
      display: flex;
      margin-top: 16px;
      justify-content: space-between;
      color: $text-secondary;
      font-size: 12px;
    }
  }

  &__progress-container {
    height: 12px;
    border-radius: $border-radius;
    position: relative;
    background: $background-default;
    width: 100%;

    &--row {
      height: 8px;
      border: 1px solid $card-color;
      margin-bottom: -20px;
    }

    &__progress {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-radius: $border-radius;
      background-color: $text-secondary;
      max-width: 100%;
      transition: width 0.3s;
    }
  }

  &__label {
    font-size: 14px;
    font-weight: 500;
    color: $text-primary;

    &--secondary {
      color: $text-secondary;
    }

    &--link {
      color: $primary-color !important;
      font-weight: 400;
      cursor: pointer;
      transition: color 0.25s ease-in-out;

      &:hover {
        color: $secondary-color !important;
      }
    }
  }
}
