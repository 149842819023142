@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  min-height: 280px;
  padding: 16px;
  background-color: $background-paper;
  border-radius: $border-radius;
  position: relative;
  overflow: hidden;

  @include media-tablet {
    min-height: unset;
    max-height: 180px;
  }

  &--v1,
  &--v2 {
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 1;
      height: 100%;
      object-fit: cover;
    }
  }

  &--v1 {
    &::before {
      width: 100%;
      background: linear-gradient(
        to right,
        $background-paper 25%,
        transparent 100%
      );

      @include media-tablet {
        background: linear-gradient(
          to bottom,
          transparent -40%,
          $card-color 50%
        );
      }
    }
  }

  &--v2 {
    &::before {
      width: 50%;
      background: $background-paper;
      clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
    }
  }

  &__background {
    position: absolute;
    inset: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include media-tablet {
      object-position: 82%;
    }

    &__holder {
      width: 100%;
      display: none;

      @media (max-width: 900px) {
        display: block;
      }
    }
  }

  &__content {
    width: 100%;
    max-width: 50%;
    height: 100%;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    z-index: 2;

    @media (max-width: 900px) {
      max-width: 100%;
    }
  }

  &__column {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 32px;

    @media (max-width: 900px) {
      max-width: 100%;
      align-items: center;
      gap: 16px;
    }
  }

  &__button__row {
    width: 100%;
    max-width: 55%;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    @media (max-width: 900px) {
      max-width: 100%;
      justify-content: space-between;
    }
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__title {
    font-size: 34px;
    line-height: 42px;
    color: $text-primary;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 16px;
    z-index: 1;
    text-align: center;

    @media (max-width: 900px) {
      width: 100%;
      font-size: 18px;
      max-width: unset;
      text-align: start;
    }
  }

  &__trail {
    display: flex;
    align-items: center;
    gap: 14px;
    z-index: 1;

    @media (max-width: 900px) {
      display: none;
    }

    &__separator {
      font-size: 0.5rem;
      color: $primary-color;
    }

    &__item {
      font-size: 14px;
      line-height: 18px;
      color: $text-primary;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 4px;

      @media (max-width: 768px) {
        gap: 1px;
        font-size: 12px;
      }
    }
  }

  &__media {
    all: unset;
    box-sizing: border-box;
    height: $box-height;
    padding: 0 16px;
    border-radius: $border-radius;
    border: 1px solid $background-default;
    background-color: $background-paper;
    color: $text-primary;
    font-size: 24px;
    line-height: 18px;
    font-weight: 600;
    position: relative;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
      background-color: $background-default;
    }

    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      flex-basis: unset;
    }
  }

  &__rank__progress {
    &__container {
      width: 100%;
      max-width: 325px;

      @media (max-width: 768px) {
        max-width: 100%;
      }
    }
  }
}
