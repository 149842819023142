@import "@/styles/theme/main.scss";
.icon {
  width: 56px;
  min-width: 56px;
  height: 56px;
  position: relative;
  transition: all 0.3s;

  &:hover {
    transform: translateY(10%);
  }

  &__image {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    object-fit: cover;
    background-position: bottom left;
    background-color: $background-paper;
    padding: 4px;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    z-index: 0;
    background: linear-gradient(
      -45deg,
      $secondary-color,
      #e73c7e,
      $primary-color,
      $success-color
    );

    background-size: 400% 400%;
  }
}
