@import "@/styles/theme/main.scss";
.card {
  width: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  background-color: $card-color;
  border-radius: $border-radius;
  gap: 8px;
  padding: 16px;
  position: relative;
  z-index: 0;
  will-change: transform;
  transition: transform 0.3s;

  &--biggest-wins {
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      z-index: 1;
      background: linear-gradient(45deg, $primary-color -25%, transparent 30%);
      opacity: 0.5;
      border-radius: $border-radius;
    }
  }

  &--luckiest {
    &::after {
      background: linear-gradient(45deg, $success-color -25%, transparent 30%);
    }
  }

  &__image {
    width: 80px;
    height: 80px;
    border-radius: $border-radius;
    z-index: 2;
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    z-index: 2;

    &__wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: 22px auto;
      gap: 0px;
      align-items: center;

      &__photo {
        width: 17px;
        height: 17px;
        border-radius: 50%;
      }

      &__name {
        color: $text-primary;
        font-size: 12px;
        display: flex;
        font-weight: 700;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &--private {
          display: flex;
          align-items: center;
          gap: 8px;
          pointer-events: none;
          color: $text-secondary;

          & p {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &--coin {
          color: $success-color;
          @include lighen(30%);
        }

        &--primary {
          width: fit-content;
          white-space: unset;
          overflow: unset;
          text-overflow: unset;
          color: $primary-color;
          font-size: 14px;
          text-align: right;
        }

        &--success {
          width: fit-content;
          white-space: unset;
          overflow: unset;
          text-overflow: unset;
          color: $success-color;
          font-size: 14px;
          text-align: right;
        }
      }
    }
  }

  &__description {
    width: 100%;
    max-width: 120px;
    margin-right: auto;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $text-primary;
  }

  &__row {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 8px;
  }

  &:hover {
    transform: translateY(-10px);
  }

  @include media-tablet {
    min-width: 250px;

    &:hover {
      transform: unset;
    }
  }
}
