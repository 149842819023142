@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  position: absolute;
  height: calc(100% - 100px);
  top: 0;
  z-index: 1;

  &--left {
    left: 0;
    width: 50%;
  }

  &--right {
    left: 50%;
    width: 50%;
  }
}
