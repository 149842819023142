@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;

  @include hide-scrollbar;

  &__mobile__only {
    display: none;

    @include media-tablet {
      display: block;
    }
  }

  &__desktop__only {
    width: 100%;
    display: block;

    @include media-tablet {
      display: none;
    }
  }
}
